
<template>
<div>
  <v-text-field
    :label="label"
    :placeholder="placeholder"
    :value="value"
    @change="onUpdateValue($event)"
    persistent-placeholder
    dense
    outlined
    background-color="white"
    append-icon="mdi-calendar-month"
    @click:append="onToggleCalendar"
    :rules="rules"
  />
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      max-width="290">
      <v-card>
        <v-date-picker
        :value="value"
        @change="onUpdateValueAndTogglePicker($event)">
        </v-date-picker>
      </v-card>
    </v-dialog>
  </v-row>
</div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'dateInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: { type: String, required: false },
    placeholder: { type: String, required: false },
    value: { type: String, required: false },
    minDate: { type: String, required: false },
    maxDate: { type: String, required: false },
    rules: { type: Array, required: false },
  },
  components: {
  },
  setup(props, context) {
    const isOpen = ref(false);
    const onToggleCalendar = () => {
      isOpen.value = !isOpen.value;
    };

    const onUpdateValue = (value) => {
      context.emit('change', value);
    };

    const onUpdateValueAndTogglePicker = (value) => {
      onUpdateValue(value);
      onToggleCalendar();
    };

    return {
      isOpen,
      onToggleCalendar,
      onUpdateValue,
      onUpdateValueAndTogglePicker,
    };
  },
};
</script>

<style>

</style>